import { Link } from "react-router-dom";
import "../styles/login.scss";
import { CartelPage } from "./CartelPage";

function LegalPage({
  extraLink,
}: {
  extraLink?: { to: string; text: string };
}) {
  const mail = "mailto";
  const part2 = ["noussommeslacompagnie", "fr"];
  const part1 = ["con", "tact"];
  const total = `${part1.join("")}@${part2.join(".")}`;
  return (
    <CartelPage extraLink={extraLink}>
      <div>
        <h3>Mentions Légales</h3>
        <p>
          Site édité par <strong>La Compagnie des Projets Secondaires</strong>,
          SAS au capital de 1000€
        </p>
        <p>RCS Lyon 877 899 286 00026</p>
        <p>Numéro de TVA Intra-Communautaire: FR42877899286</p>
        <p>
          Contact disponible à <Link to={`${mail}:${total}`}>{total}</Link>
        </p>
        <br></br>
        <p>
          Ce site est hébergé par <Link to="ovh.com">OVH</Link>, SAS 2 rue
          Kellermann - 59100 Roubaix - France
        </p>
      </div>
    </CartelPage>
  );
}

export default LegalPage;
