import { ReactNode, useEffect, useRef, useState } from "react";

type PropsType = {
  onEvent: () => void;
  startDelayMs?: number;
  intervalMs?: number;
  children?: ReactNode | undefined;
} & React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

export function HoldableButton({
  onEvent,
  startDelayMs = 400,
  intervalMs = 100,
  children,
  ...otherProps
}: PropsType) {
  const interval = useRef<NodeJS.Timer | null>(null);
  const [isPressed, setIsPressed] = useState(false);
  const [startPhase, setStartPhase] = useState(true);

  useEffect(() => {
    if (isPressed) {
      interval.current = setInterval(
        () => {
          setStartPhase(false);
          onEvent();
        },
        startPhase ? startDelayMs : intervalMs
      );
    } else {
      if (interval.current) {
        clearInterval(interval.current);
      }
      interval.current = null;
    }

    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, [isPressed, startDelayMs, intervalMs, onEvent, startPhase]);

  return (
    <button
      onMouseDown={(e) => {
        e.preventDefault();
        setIsPressed(true);
        onEvent();
      }}
      onMouseUp={(e) => {
        e.preventDefault();
        setIsPressed(false);
        setStartPhase(true);
      }}
      onMouseLeave={(e) => {
        e.preventDefault();
        setIsPressed(false);
        setStartPhase(true);
      }}
      {...otherProps}
    >
      {children}
    </button>
  );
}
