import { useState } from "react";
import "../../messages/MessagesBase.scss";
import { CodeFormProps } from "../CodeFormInfos";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/ReduxStore";
import { useTimerPercent } from "../../../tools/TimeTools";
import { StoryCodes, StoryConstants } from "../../../shared/StoryConstants";
import Config from "../../../Config";
import { BackButtonFlex } from "../../../parts/BackButton";
import { checkCodeShared } from "../CodeApp";
import { useNavigate } from "react-router";
import { onSuccessNavigation } from "../CodeUtils";

function ConfirmationModal({
  popupVisible,
  onClick,
  hidePopup,
}: {
  popupVisible: boolean;
  onClick: () => void;
  hidePopup: () => void;
}) {
  const crossSize = "2.5rem";
  const sizeStyle = (size: string | number): React.CSSProperties => {
    return {
      height: size,
      width: size,
      minWidth: size,
      minHeight: size,
      maxHeight: size,
      maxWidth: size,
    };
  };

  return (
    <div className={"modal " + (popupVisible ? "is-active" : "")}>
      <div
        className="modal-background"
        onClick={(e) => {
          e.preventDefault();
          hidePopup();
        }}
      ></div>
      <div className="modal-content">
        <div className="box">
          <h2>Confirmation</h2>
          <div>
            <p>
              En suivant les quatres premiers SMS vous devriez atteindre une
              place. Est-ce que vous nous confirmez que vous êtes à destination
              ?
            </p>
          </div>
          <div className="field has-addons" style={{ marginTop: "1rem" }}>
            <p className="control" style={{ flexGrow: 1 }}>
              <button
                className="button is-fullwidth is-family-monospace is-active"
                onClick={(e) => {
                  e.preventDefault();
                  onClick();
                }}
              >
                Oui
              </button>
            </p>
            <p className="control" style={{ flexGrow: 1 }}>
              <button
                className="button is-fullwidth is-family-monospace is-red is-active"
                onClick={(e) => {
                  e.preventDefault();
                  hidePopup();
                }}
              >
                Non
              </button>
            </p>
          </div>
        </div>
      </div>
      <button
        className="modal-close button is-rounded navbar-button"
        aria-label="close"
        onClick={(e) => {
          e.preventDefault();
          hidePopup();
        }}
        style={{
          fontWeight: 800,
          ...sizeStyle(crossSize),
        }}
      ></button>
    </div>
  );
}

function ConfirmationBlock() {
  const [popupVisible, setPopupVisible] = useState(false);
  const [codeSent, setCodeSent] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  return (
    <div className="message-on-right">
      <div>
        <button
          className="button is-active message-out"
          onClick={(e) => {
            e.preventDefault();
            setPopupVisible(true);
          }}
        >
          Appuyer pour Confirmer
        </button>
      </div>
      <ConfirmationModal
        popupVisible={popupVisible}
        hidePopup={() => setPopupVisible(false)}
        onClick={() => {
          if (codeSent === false) {
            checkCodeShared({
              code: StoryCodes.SMS_VALIDATION,
              dispatch,
              onSuccess: (status) => {
                onSuccessNavigation(status, navigate);
              },
            });
            setCodeSent(true);
          }
        }}
      />
    </div>
  );
}

function StolenSmsSeries(props: { startDate: Date; isStepSms: boolean }) {
  const delayBetweenMn = Config.debug ? 0.2 : 2;
  const stolenMessages = [
    "Rendez-vous à la place ronde à gauche en sortant. ⭕⬅❌",
    "5🎲 ⬆ 5🎲",
    "🐟↗🪜",
    "🪜↘⬆↗🪜",
    "🔍(👨‍🔬🧪,👶➕💀)",
  ];

  const numberOfLoading = stolenMessages.length - 1;

  const currentSec = new Date().getTime() / 1000;
  const percent = useTimerPercent(
    props.startDate,
    numberOfLoading * delayBetweenMn,
    250
  );

  const currentIndex = props.isStepSms
    ? Math.min(
        Math.trunc(percent / (100.0 / numberOfLoading)),
        stolenMessages.length - 1
      )
    : stolenMessages.length - 1;

  const currentPercent =
    (1 * (percent - currentIndex * (100.0 / numberOfLoading))) /
    (1 / numberOfLoading);

  function MessageRendezVous({ text }: { text: string }) {
    return <p className="company message-in message-short stolen">{text}</p>;
  }

  const endMessage = (
    <>
      <p className="company message-in message-short">
        Le signal d'envoi a alors été coupé. Cependant on détecte des émissions
        hautes fréquences dans votre zone. Quand vous serez en place, nous vous
        rajouterons une application pour les intercepter.
      </p>
      {props.isStepSms ? <ConfirmationBlock /> : <></>}
    </>
  );

  return (
    <>
      {stolenMessages.slice(0, currentIndex + 1).map((v, idx) => {
        return <MessageRendezVous key={idx} text={v}></MessageRendezVous>;
      })}
      {percent >= 100 || !props.isStepSms ? (
        endMessage
      ) : (
        <p className="is-family-monospace loading loading-message">
          Déchiffrement du prochain message (
          {Math.trunc(currentPercent > 0 ? currentPercent : 0)}%)
          {Array(Math.trunc(currentSec % 4)).fill(".")}
        </p>
      )}
    </>
  );
}

export function CodeRendezVous(p: CodeFormProps | undefined) {
  const [currentCode, setCurrentCode] = useState<string>("");
  const storyStatus = useSelector((state: RootState) => state.status.status);
  const [codeSent, setCodeSent] = useState(false);

  const stepIndex = storyStatus?.step?.index;

  // Check if we found the initials
  if (
    stepIndex === StoryConstants.Steps.RENDEZ_VOUS &&
    codeSent === false &&
    currentCode.toLowerCase() === "ca"
  ) {
    if (p) {
      p.checkCode(currentCode.toUpperCase());
      setCodeSent(true);
    }
  }

  const startDate = new Date(
    storyStatus
      ? storyStatus.lastStepDate
      : new Date().getTime() - 10 * 60 * 1000
  );

  const initialDelayMn = Config.debug ? 0.2 : 2.0;

  const percent = useTimerPercent(startDate, initialDelayMn);

  const currentSec = new Date().getTime() / 1000;

  return (
    <>
      <div className="rendez-vous">
        <div
          className="message-app no-expand"
          style={{ margin: "0 auto 1.5rem auto" }}
        >
          {stepIndex === StoryConstants.Steps.RENDEZ_VOUS && percent < 100 ? (
            <p className="is-family-monospace loading is-fullwidth text-centered">
              Analyse en cours
              {Array(Math.trunc(currentSec % 4)).fill(".")}
              <br />
              {Math.trunc(percent > 0 ? percent : 0)}%
            </p>
          ) : (
            <>
              <p className="company message-in message-short">
                Nous avons intercepté un premier message.
              </p>
              <p className="company message-in message-short stolen">
                J'ai besoin de vérifier que vous êtes au rendez-vous. J'ai
                laissé mes initiales juste à la sortie du bâtiment. Les
                avez-vous ?
              </p>
              <p className="company message-in message-short">
                Il y a d'autres messages mais il faut un code pour les ouvrir
              </p>
              <div className="message-on-right">
                {stepIndex === StoryConstants.Steps.RENDEZ_VOUS ? (
                  <div className="field has-addons">
                    <div className="control">
                      <input
                        className="input message-out"
                        name="code"
                        value={currentCode}
                        onChange={(e) => setCurrentCode(e.target.value)}
                        minLength={2}
                        maxLength={2}
                        placeholder="Initiales"
                      />
                    </div>
                  </div>
                ) : (
                  <div>
                    <p className="company message-out message-short">CA</p>
                  </div>
                )}
              </div>
              {stepIndex && stepIndex >= StoryConstants.Steps.THE_SMS ? (
                <StolenSmsSeries
                  startDate={startDate}
                  isStepSms={stepIndex === StoryConstants.Steps.THE_SMS}
                />
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export function CodeRendezVousPage() {
  return (
    <>
      <div className="block-content expand message-app">
        <h2 className="app-title is-fullwidth">La Conversation</h2>
        <CodeRendezVous></CodeRendezVous>
      </div>
      <BackButtonFlex />
    </>
  );
}
