import { CityName } from "./StoryConstants";

export type StoryMainApi = { city: CityName, index: number };

export type StoryClueApi = { hint: string, index: number };

export const TIME_BETWEEN_CLUES_MS = 0.1 * 60 * 1000;

export type StoryStepApi = {
    index: number,
    name: string,
    description: string,
    maxClues: number
}

export enum StoryStates {
    COMMAND = 1,
    SENT,
    ACTIVATED,
    DONE
}

export type StoryStatusApi = {
    code: string,
    startDate: string,
    lastStepDate: string,
    lastClueDate?: string,
    state: StoryStates,
    fullOnWin: boolean,
    story: StoryMainApi | undefined,
    step: StoryStepApi | undefined,
    clueIndex: number,
    giftFrom: string,
    clues: StoryClueApi[]
};