import { LinkListPage, LinkListRoute } from "../LinkListPage";
import "../../styles/LinkListPage.scss";
import { useMessagesArray } from "./MessagesElements";

export function MessagesApp() {
  const availablesMessages = useMessagesArray();

  const messagesList = availablesMessages
    ? availablesMessages.map((d) => d.infos)
    : undefined;

  return (
    <LinkListPage
      title="Messages"
      baseUrl="home/messages"
      links={messagesList}
    />
  );
}

export function MessagesRoute() {
  const array = useMessagesArray();
  return <LinkListRoute array={array} />;
}
