import { useSelector } from "react-redux";
import { RootState } from "../../redux/ReduxStore";
import { BackButtonFlex } from "../../parts/BackButton";
import "./CodeApp.scss";
import { useNavigate } from "react-router";
import { CodeFormsInfos } from "./CodeUtils";
import { Expand } from "../../parts/Expand";
import { StoryConstants } from "../../shared/StoryConstants";

export function FrequencyApp() {
  const navigate = useNavigate();
  const statusState = useSelector((state: RootState) => state.status);

  // Check access
  if (
    statusState.status?.story?.index === StoryConstants.Story.FIRST_MISSION &&
    statusState.status?.step &&
    statusState.status?.step?.index <= StoryConstants.Steps.LA_PLACE
  ) {
    //
    navigate("/home");
  }

  const formInfos = CodeFormsInfos.LA_PLACE;
  const correct = false;
  const errorMessage = null;

  async function checkCode(code: string) {
    // NO CHECK IN THIS APP
  }

  return (
    <>
      <div
        className={`block-content ${
          formInfos.isCentered ? "centered-body" : ""
        } text-center code-app-body`}
      >
        <div className="columns normal" style={{ marginBottom: "1.5rem" }}>
          <div className="column">
            <h3>{formInfos.header}</h3>
            <p className="thin">{formInfos.subheader}</p>
          </div>
        </div>
        <div className="columns is-fullwidth code-app-code-columns">
          <div className="column is-fullwidth is-centered">
            <formInfos.formElement
              {...{
                correct,
                formInfos,
                checkCode,
                errorMessage,
              }}
            />
          </div>
        </div>
      </div>
      <Expand />
      <BackButtonFlex />
    </>
  );
}
