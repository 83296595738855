import Landing from "./pages/landing/Landing";
import {
  Route,
  RouterProvider,
  Routes,
  createBrowserRouter,
  useLocation,
  useNavigate,
} from "react-router-dom";

import "./styles/bulma.min.scss";
import "./styles/style.scss";
import "./styles/header.scss";
import "./styles/sizeRestriction.scss";
import "./styles/fade.css";
import "./styles/imagesContainer.scss";
import LoginPage from "./pages/login/LoginPage";
import LoginForm from "./pages/login/LoginForm";
import { CGUPage, CGURegister } from "./parts/CGU";
import LegalPage from "./pages/LegalPage";
import { HeaderFooterStructure } from "./structure/HeaderFooterStructure";
import { HomePage } from "./pages/home/HomePageLayout";
import { NavbarStructure } from "./structure/NavbarStructure";
import Logout from "./parts/Logout";
import { NotFoundPage } from "./pages/NotFoundPage";
import { Provider } from "react-redux";
import { ReduxStore } from "./redux/ReduxStore";
import { CodeApp } from "./pages/code/CodeApp";
import { DocumentsApp, DocumentsRoute } from "./documents/DocumentsApp";
import { SettingsPage } from "./pages/SettingsPage";
import { AdminPage } from "./pages/admin/AdminPage";
import { MessagesApp, MessagesRoute } from "./pages/messages/MessagesApp";
import { StoreActivationCodeAndRedirect } from "./redux/ActivationCode";
import PasswordResetForm from "./pages/login/PasswordReset";
import { AdminUnsent } from "./pages/admin/AdminUnsent";
import { CGVentesPage } from "./parts/CGVentes";
import { FrequencyApp } from "./pages/code/FrequencyApp";
import { LoadingPage } from "./pages/LoadingPage";
import { useEffect } from "react";
import CreditsApp from "./pages/app/CreditsApp";
import { ConceptPage } from "./pages/ConceptPage";
import { StoreAdCampaignCodeAndRedirect } from "./redux/AdCampaignCode";
import UnsubscribeNewsletterPage from "./pages/UnsubscribleNewsletterPage";
import { AdminExplorer } from "./pages/admin/AdminExplorer";
import { PromoCodePage } from "./pages/promoCode/PromoCodePage";

var isFirstTime = true;

export function RootHome() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/home");
  });
  return <LoadingPage />;
}

function Root() {
  const location = useLocation();
  const isLanding = location.pathname === "/";
  isFirstTime = isFirstTime && isLanding;
  const app = (
    <>
      <Provider store={ReduxStore}>
        <Routes>
          <Route path="/home" element={<NavbarStructure />}>
            <Route index element={<HomePage />} />
            <Route path="settings" element={<SettingsPage />} />
            <Route path="legal" element={<LegalPage />} />
            <Route path="cgv" element={<CGVentesPage />} />

            <Route path="app">
              <Route path="code" element={<CodeApp />} />
              <Route path="frequencies" element={<FrequencyApp />} />
              <Route path="documents" element={<DocumentsApp />} />
              <Route path="messages" element={<MessagesApp />} />
              <Route path="credits" element={<CreditsApp />} />
              <Route path="*" element={<RootHome />} />
            </Route>
            <Route path="messages/:currentUrl" element={<MessagesRoute />} />
            <Route path="cgu" element={<CGUPage />} />
          </Route>
          <Route
            path="/a/:activationCode"
            element={<StoreActivationCodeAndRedirect />}
          />
          <Route
            path="/c/:campaignCode"
            element={<StoreAdCampaignCodeAndRedirect />}
          />
          <Route path="/document/:currentUrl" element={<DocumentsRoute />} />
          <Route path="/admin" element={<NavbarStructure />}>
            <Route index element={<AdminPage />} />
            <Route path="unsent" element={<AdminUnsent />} />
            <Route path="explorer" element={<AdminExplorer />} />
            <Route path="documents/:currentUrl" element={<LegalPage />} />
          </Route>
          <Route
            path="/"
            element={
              <HeaderFooterStructure
                useDelay={isLanding && isFirstTime}
                useTagline={isLanding}
              />
            }
          >
            <Route index element={<Landing useDelay={isFirstTime} />} />

            <Route path="/concept" element={<ConceptPage />} />
            <Route
              path="/unsubscribe"
              element={<UnsubscribeNewsletterPage />}
            />
            <Route
              path="/legal"
              element={
                <LegalPage
                  extraLink={{
                    to: "/cgv",
                    text: "Conditions Générales de Ventes",
                  }}
                />
              }
            />
            <Route path="/cgv" element={<CGVentesPage />} />
            <Route path="/login" element={<LoginPage />}>
              <Route index element={<LoginForm />} />
              <Route path="cgu" element={<CGURegister />} />
            </Route>

            <Route path="/password" element={<LoginPage />}>
              <Route path="reset" element={<PasswordResetForm />} />
            </Route>

            <Route path="/code" element={<PromoCodePage />} />

            <Route path="/logout" element={<Logout />} />
            <Route path="*" element={<NotFoundPage />} />
          </Route>
        </Routes>
      </Provider>
    </>
  );

  return app;
}

const router = createBrowserRouter([{ path: "*", Component: Root }]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
