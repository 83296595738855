import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/ReduxStore";
import { BackButtonFlex } from "../../parts/BackButton";
import "./CodeApp.scss";
import Config from "../../Config";
import { RequestOptions } from "../../tools/RequestOptions";
import { StatusSliceActions } from "../../redux/status/StatusSlice";
import { useNavigate } from "react-router";
import { getCodeFormInfos, onSuccessNavigation } from "./CodeUtils";
import { Expand } from "../../parts/Expand";
import { LoadingPage } from "../LoadingPage";

export async function checkCodeShared({
  code,
  dispatch,
  onSuccess,
  onError,
}: {
  code: string;
  dispatch: AppDispatch;
  onSuccess?: (a: any) => void;
  onError?: (a: Response) => void;
}) {
  const response = await fetch(
    Config.endpoint + "code/check",
    RequestOptions({
      method: "POST",
      body: JSON.stringify({ code: code }),
    })
  );

  if (response.status === 200) {
    const status = await response.json();
    dispatch(StatusSliceActions.stepChanged(status));

    if (onSuccess) {
      onSuccess(status);
    }
  } else if (onError) {
    onError(response);
  }
}

export function CodeApp() {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [correct, setCorrect] = useState<boolean>(false);
  const [validating, setValidating] = useState<boolean>(false);
  const navigate = useNavigate();

  const statusState = useSelector((state: RootState) => state.status);

  const dispatch = useDispatch<AppDispatch>();

  async function checkCode(code: string) {
    setErrorMessage(null);
    setValidating(true);
    checkCodeShared({
      code,
      dispatch,
      onSuccess: (status) => {
        setCorrect(true);
        setValidating(false);
        onSuccessNavigation(status, navigate);
      },
      onError: (response) => {
        if (response.status === 404) {
          setErrorMessage("Code erroné");
        } else {
          setErrorMessage("Problème validation");
        }

        setValidating(false);
      },
    });
  }

  const formInfos = getCodeFormInfos(statusState.status);

  if (!validating && formInfos === undefined) {
    navigate("/home");
  }

  return formInfos ? (
    <>
      <div
        className={`block-content ${
          formInfos.isCentered ? "centered-body" : ""
        } text-center code-app-body`}
      >
        <div className="columns normal" style={{ marginBottom: "1.5rem" }}>
          <div className="column">
            <h3>{formInfos.header}</h3>
            <p className="thin">{formInfos.subheader}</p>
          </div>
        </div>
        <div className="columns is-fullwidth code-app-code-columns">
          <div className="column is-fullwidth is-centered">
            <formInfos.formElement
              {...{
                correct,
                formInfos,
                checkCode,
                errorMessage,
              }}
            />
          </div>
        </div>
      </div>
      <Expand />
      <BackButtonFlex />
    </>
  ) : (
    <LoadingPage />
  );
}
