import { ReactElement, useRef, useState } from "react";
import { StoryStatusApi, StoryStepApi } from "../../../../shared/StoryElements";
import {
  AskForNewClue,
  ClueButton,
  LockedClueButton,
  NextClueButton,
} from "./ElementsCluePopup";
import { ClueTimerInfos, useClueTimer } from "./TimerCluePopup";
import { SwitchTransition } from "react-transition-group";
import { FadeTransition } from "../../../../parts/Transitions";

function nextClueSentence(timerInfos: ClueTimerInfos) {
  return (
    "Prochain indice " +
    (timerInfos.nextAvailable
      ? "disponible"
      : `dans ${String(Math.trunc(timerInfos.secondsLeft / 60)).padStart(
          2,
          "0"
        )}:${String(Math.trunc(timerInfos.secondsLeft % 60)).padStart(2, "0")}`)
  );
}

export function RealCluePopup(props: {
  storyStatus: StoryStatusApi;
  step: StoryStepApi;
}) {
  const storyStatus = props.storyStatus;
  const step = props.step;
  const timerInfos: ClueTimerInfos = useClueTimer(storyStatus);
  const [askingForNewClue, setAskingForNewClue] = useState(false);
  const [prevClueIndex, setPrevClueIndex] = useState(storyStatus.clueIndex);
  const [currentClueIndex, setCurrentClueIndex] = useState(
    storyStatus.clueIndex
  );

  if (prevClueIndex !== storyStatus.clueIndex) {
    setPrevClueIndex(storyStatus.clueIndex);
    setCurrentClueIndex(storyStatus.clueIndex);
  }

  const buttons: ReactElement[] = [];
  for (var i = 0; i <= step.maxClues; i++) {
    if (i <= storyStatus.clueIndex) {
      const j = i;
      buttons.push(
        ClueButton(i, i === currentClueIndex, () => setCurrentClueIndex(j))
      );
    } else if (i === storyStatus.clueIndex + 1) {
      buttons.push(NextClueButton(timerInfos, i, setAskingForNewClue));
    } else {
      buttons.push(LockedClueButton(i));
    }
  }

  const nodeRef = useRef(null);

  return (
    <div className="popup" key={storyStatus.clueIndex}>
      <h2>{step.name}</h2>
      <SwitchTransition mode="out-in">
        <FadeTransition key={currentClueIndex.toString()}>
          <p>
            {currentClueIndex === 0
              ? step.description
              : storyStatus.clues[currentClueIndex - 1].hint}
          </p>
        </FadeTransition>
      </SwitchTransition>
      <SwitchTransition mode="out-in">
        <FadeTransition
          key={askingForNewClue ? "new-clue" : "button-list"}
          nodeRef={nodeRef}
        >
          <div className="popup-button-box">
            {askingForNewClue ? (
              <AskForNewClue
                isLast={storyStatus.clueIndex === step.maxClues - 1}
                goBack={(v) => {
                  setAskingForNewClue(false);
                }}
              />
            ) : (
              <>
                <p className="is-family-monospace">
                  {storyStatus.clueIndex >= step.maxClues
                    ? "Solution Disponible"
                    : nextClueSentence(timerInfos)}
                </p>
                <div className="field has-addons">{buttons}</div>
              </>
            )}
          </div>
        </FadeTransition>
      </SwitchTransition>
    </div>
  );
}
