import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/ReduxStore";
import { RealCluePopup } from "./RealCluePopup";

export function DefaultCluePopup() {
  const storyStatus = useSelector((state: RootState) => state.status.status);

  if (storyStatus === null || storyStatus.step === undefined) {
    return (
      <div className="popup">
        <p>Le jeu n'est pas correctement initialisé</p>
      </div>
    );
  } else {
    return <RealCluePopup storyStatus={storyStatus} step={storyStatus.step} />;
  }
}
